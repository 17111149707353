import {Structures} from "octopus-model";

export const cards = {
    displayOnlyMediaIconsType: true,
    cardDisplay: {
        displayLessonBreadcrumb: {concepts: false, chapters: false, skills: true},
        showButtonThemeLesson: false,
        showButtonBookmarks: {
            themes: true,
            lesson: true,
            model: true,
            community: true,
            image: true,
            video: true,
            videoUrl: true,
            audio: true,
            document: true,
            url: true,
        },
        isTextButton: true,
    },
    cardFields: {
        default: {
            theme: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty', 'target-age'],
            lesson: ['title', 'titleIcon', 'menu', 'image', 'description', 'educationalLevel', 'keywords-chip-list', 'button-assign-as-label-in-action-menu', 'difficulty', 'author', 'share-lesson'],
            model: ['title', 'titleIcon', 'menu', 'image', 'description', 'educationalLevel', 'keywords-chip-list', 'button-assign-as-label-in-action-menu', 'difficulty', 'author', 'share-lesson'],
            community: ['title', 'titleIcon', 'menu', 'description', 'image', 'theme', 'usage', 'skills', 'difficulty', 'target-age', 'author', 'share-lesson'],
            image: ['title', 'titleIcon', 'menu', 'image', 'difficulty', 'description', 'target-age'],
            video: ['title', 'titleIcon', 'menu', 'image', 'difficulty', 'description', 'target-age'],
            videoUrl: ['title', 'titleIcon', 'menu', 'image', 'difficulty', 'description', 'target-age'],
            audio: ['title', 'titleIcon', 'menu', 'image', 'difficulty', 'description', 'target-age'],
            document: ['title', 'titleIcon', 'menu', 'image', 'difficulty', 'description', 'target-age'],
            url: ['title', 'titleIcon', 'menu', 'image', 'difficulty', 'description', 'target-age'],
        }
    },
    cardDefaultHeader: '/assets/cycle1tnecanope/images/backgrounds/img_default_lesson.svg',
    cardPlayOpenMenu: false,
    isLaunchLessonAskModalActive: {default: true},
    isLaunchLessonAskTitle: true,
    menuLinks: {
        lesson: {
            default: [],
            trainer: ['edit']
        },
        model: {
            default: []
        }
    },
    lessonPageMenuActions: {
        lesson: {
            default: [],
            trainer: ['edit']
        },
        model: {
            default: []
        }
    },
    multiPdfDownload: false,
    playButtonUseIcon: false,
    hidePlayPreviewFromFavoritesPage: true,
    allowedMultiPdfDownloadFromFavoritePage: true,
    viewLessonPage: true, //allow to show lesson page,
    moveActionsButtonInMenu: {
        lesson: true,
        default: false
    }// Display all button in menu button except bookmark and open lesson page
};
